.popup-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1000;
    overflow: hidden;
  }
  
  .popup-content {
    padding: 20px;
  }
  
  .close-btn {
    float: right;
    font-size: 24px;
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .tab {
    padding: 10px 20px;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 16px;
    border-bottom: 2px solid transparent;
  }
  
  .tab.active {
    border-bottom: 2px solid red;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="password"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s;
  }
  
  input:focus {
    outline: none;
  }
  
  input.valid {
    border-color: green;
  }
  
  input.invalid {
    border-color: red;
  }
  
  .submit-btn {
    width: 100%;
    padding: 10px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-btn:hover {
    background-color: #e60000;
  }
  
  .message {
    color: green;
    text-align: center;
  }
  
  .error-text {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    display: block;
  }
/* Add this to PopUp.css */

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
    backdrop-filter: blur(5px); /* Apply the blur effect */
    z-index: 1000; /* Ensure it sits below the popup modal */
  }
  
  .popup-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001; /* Sits above the overlay */
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  .form-group.half-input>input {
    margin-top: 20px;
}
    