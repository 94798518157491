/* SearchPopup.css */

.search-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark background with blur effect */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure it sits above other content */
  }
  
  .search-popup {
    background: white;
    border-radius: 8px;
    padding: 20px;
    max-width: 600px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  /* SearchPopup.css */

.error-message {
    color: red;
    margin-top: 10px;
  }
  
  .close-button {
    position: absolute;
    top: -10px;
    right: -15px;
    background: #ff4d4d;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
  }
  
  .close-button:hover {
    background: #e03a3a;
  }
  
  .search-popup input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .dropdown-menu {
    margin-top: 10px;
    padding: 0;
    list-style: none;
    max-height: 200px;
    overflow-y: auto;
  }
  
  .dropdown-menu li {
    padding: 10px;
    border-bottom: 1px solid #eee;
  }
  
  .dropdown-menu li:last-child {
    border-bottom: none;
  }
  
  .dropdown-menu h4 {
    margin: 0;
    font-size: 16px;
  }
  
  .dropdown-menu p {
    margin: 5px 0 0;
    color: #666;
  }
  