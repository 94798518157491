/* BlogList.css */
.blog-list {
  padding: 20px;
}

.articles-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
}

.article-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.article-card:hover {
  transform: scale(1.05);
}

.article-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.article-content {
  padding: 15px;
}

.article-category {
  color: #f44336;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.article-title {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
}

.article-author {
  color: #888;
  font-size: 14px;
  margin-bottom: 10px;
}

.article-time {
  font-size: 14px;
  color: #fc0000;
}

@media (min-width: 768px) {
  .articles-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1024px) {
  .articles-container {
    grid-template-columns: repeat(4, 1fr);
  }
}
